.ProseMirror {
  padding: 10px;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  &:focus-visible {
    outline: none;
  }
  p {
    font-family: Lexend;
    color: #212529;
  }
  h6,
  .h6,
  h5,
  .h5,
  h4,
  .h4,
  h3,
  .h3,
  h2,
  .h2,
  h1,
  .h1 {
    color: #212529;
  }
}

svg {
  &:focus {
    outline: unset;
  }
}

.loree-tiptap-editor {
  height: 220px;
  overflow-y: auto;

  padding: 3px 0px;
  &:focus-visible {
    outline: none;
  }

  .tiptap {
    cursor: auto;
    height: 100%;
  }

  ul,
  ol {
    padding-left: 40px;
  }

  p,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0px;
    margin: 0px;
  }

  blockquote {
    border-left: 4px solid #ccc;
    padding: 15px;
  }

  img,
  iframe {
    height: auto;
    max-width: 100%;
  }

  .column-resize-handle {
    background-color: #adf;
    bottom: -2px;
    position: absolute;
    right: -2px;
    pointer-events: none;
    top: 0;
    width: 4px;
  }

  .resize-cursor {
    cursor: ew-resize;
  }

  .selectedCell {
    position: relative;
  }

  .selectedCell:after {
    background: rgba(200, 200, 255, 0.4);
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 2;
  }

  th,
  td {
    position: relative;
  }
}

svg.textFormatButton path {
  fill: #000000;
}

.is-active svg.textFormatButton path {
  fill: rgb(17, 34, 153) !important;
}

.padRight12px {
  padding-right: 12px;
}

.padRight0 {
  padding-right: 0px !important;
}

.padLeft12px {
  padding-left: 12px;
}

.modalBodyGroupContent {
  position: relative;
  width: 100%;
}

.searchIcon {
  position: absolute;
  right: 2rem !important;
  top: 11px !important;
  z-index: 11;
  path {
    fill: #495057;
  }
}

.borderSearchIcon {
  position: absolute;
  right: 4% !important;
  top: 66% !important;
  z-index: 9;
  path {
    fill: #495057;
  }
}

.tableModalHeader {
  padding: 16px !important;
  .tableHeaderTitle {
    font-family: Lexend;
    font-size: 16px;
    font-weight: 400;
    color: #585858;
    margin: 0px;
  }
  .btn-close {
    font-size: 9px;
    padding-right: 0px !important;
    &:focus {
      box-shadow: unset !important;
    }
  }
}

.tableBodySection {
  padding: 0px !important;
  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    opacity: unset;
  }
  div[tabindex='-1'] {
    border: 1px solid #d8d8d8 !important;
    color: #585858;
    background-color: #fff !important;
    li,
    span {
      &:hover {
        background-color: #f2f2f2 !important;
        color: #585858 !important;
      }
    }
    li[data-selected='true'][tabindex='1'] {
      background-color: #f2f2f2 !important;
      color: #1c1c1c !important;
    }
  }
}

.alignmentButtonTableProperties {
  cursor: pointer;
  path {
    fill: #585858;
  }
  &:hover,
  &.active {
    path {
      fill: #112299;
    }
  }
}

.tableBorderStyleLabel {
  margin-top: 4px !important;
  margin-right: 10px;
}

.tablePropertyLabelSection {
  width: 100%;
  font-size: 16px;
  margin-top: 0.5rem;
  white-space: nowrap;
  height: 18px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
  color: #909090;
}

.tableAlignmentpropertySection {
  margin-top: 7px;
  justify-content: space-between;
}

.colorPicker[aria-expanded='false'],
.colorPicker[aria-expanded='true'] {
  margin-left: 8px;
  border: 1px solid #ccc !important;
  padding: 8px 16px;
  width: 34px !important;
  height: 34px !important;
  border: 1px solid #e9e9e9;
  border-radius: 8px !important;
}

.lint-checkbox {
  accent-color: #129;
  cursor: pointer;
  &:checked {
    background-color: #129 !important;
    border-color: #129;
    accent-color: #129;
  }
  &:focus {
    border-color: #129;
    box-shadow: 0 0 0 0.2rem rgba(17, 34, 153, 0.25);
  }
}

.tableFormContent {
  padding-left: 16px !important;
  padding-right: 16px !important;
  label {
    color: #585858;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
  }

  select {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
    border: 1px solid #ced4da;
    color: #495057;
    &.loree-interactive-table-row-input {
      margin-left: 7rem !important;
      width: 20% !important;
      border-radius: 6px;
    }

    &.loree-interactive-table-column-input {
      margin-left: 5.4rem;
      width: 20% !important;
      border-radius: 6px;
    }
  }

  .form-check-label {
    cursor: pointer;
  }

  input[type='checkbox'] {
    accent-color: #129;
    border-color: #8f8f9d;
    border-width: 2px;
    border-radius: 3px;
    position: absolute;
    margin-top: 0.37rem;
    margin-left: -1.35rem;
    height: 14px;
    width: 14px;
    cursor: pointer;
    &:checked {
      background-color: #129 !important;
      border-color: #129;
      accent-color: #129;
    }
    &:focus {
      border-color: #129;
      box-shadow: 0 0 0 0.2rem rgba(17, 34, 153, 0.25);
    }
  }

  .tableCaptionLabel {
    color: #585858;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    display: inline-block;
  }
}

.loree-table-text-element {
  font-size: 14px !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #d8d8d8 !important;
  font-style: normal;
  font-weight: 350 !important;
  font-family: Lexend;
  &:focus {
    color: #585858 !important;
    border-color: #707070 !important;
    box-shadow: unset !important;
  }
}

.lintTableTextBox {
  padding: 5px !important;
  border-radius: 2px !important;
  height: 28px;
  border: 1px solid #e9e9e9 !important;
  border-radius: 4px !important;
  color: #000 !important;
  &:focus {
    box-shadow: unset !important;
    border-color: #e9e9e9 !important;
  }
}

.borderWidthContent {
  color: #585858 !important;
  padding: 8px 16px;
  width: 96px !important;
  height: 34px;
  background: #ffffff;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
  &:hover,
  &:focus {
    border: 1px solid #e9e9e9 !important;
  }
}

.borderStyleContent {
  width: 210px !important;
  float: right;
  margin: auto 0 auto auto;
}

.lintTableInfoMessage {
  font-size: 12px !important;
  color: #6c757d !important;
}

.modalCancelButton {
  color: #585858 !important;
  background-color: #fff !important;
  border-color: #d8d8d8 !important;
  padding: 5px 20px !important;
  font-size: 14px !important;
  border-radius: 8px;
  font-weight: 400;
  &:hover {
    background-color: #d8d8d8 !important;
  }
}

.modalOkButton {
  color: #fff !important;
  background: #129 0 0 no-repeat padding-box !important;
  border-color: #129 !important;
  border-radius: 8px;
  padding: 5px 20px !important;
  font-size: 14px !important;
  &.disabled {
    opacity: 0.5 !important;
    cursor: not-allowed;
  }
  &:hover {
    background-color: #0e1b7a !important;
  }
}

.btn.modalOkButton:disabled {
  opacity: 1;
  cursor: not-allowed;
  background-color: #e9e9e9 !important;
  color: #707070 !important;
  border: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.sizeSection {
  .lintTableTextBox {
    width: 96px !important;
    height: 34px !important;
    border-radius: 8px !important;
  }
}

.borderWidthSection {
  padding-right: 10px;
  margin-left: 10px !important;
}

.paddingFlexContent {
  flex: 1 1 0%;
}

.paddingTwoSection {
  gap: 5px;
}

.paddingInputBox {
  height: 34px;
  background: rgb(255, 255, 255) 0px 0px no-repeat padding-box;
  opacity: 1;
  box-shadow: unset !important;
  border: 1px solid #e9e9e9;
  border-radius: 8px !important;
  padding: 8px 16px !important;
  color: #8f8f8f !important;
  font-size: 14px;
  font-family: Lexend;
  font-weight: 350;
  &:focus {
    border: 1px solid #e9e9e9 !important;
    color: #8f8f8f !important;
  }
}

.paddingLabel {
  width: 27px;
  height: 18px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
  color: #909090;
}

.left-pad {
  padding: 0px;
  margin-bottom: 10px;
}

.right-pad,
.top-pad {
  padding: 0px;
  margin-bottom: 10px;
}

.left-pad,
.right-pad,
.top-pad,
.bottom-pad {
  width: 95%;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 336px !important;
  }
}

.paddingBlock {
  width: 56px;
  height: 18px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
  color: #1c1c1c;
}

.colorInputbox {
  box-sizing: border-box;
  gap: 8px;
  width: 96px;
  height: 34px;
  overflow-x: scroll;
  background: #ffffff;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  padding-left: 10px !important;
  color: #585858;
  &:hover,
  &:focus-visible {
    outline: none;
    border: 1px solid #e9e9e9 !important;
  }
}

.borderInput > button {
  padding: 8px 16px;
  width: 320px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #585858;
  &:hover,
  &:focus {
    background: #ffffff;
    border: 1px solid #e9e9e9 !important;
  }
}

.borderInput > div {
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
  color: #585858;
  a {
    &:hover {
      background-color: #f2f2f2 !important;
      color: #585858 !important;
    }
    &.active {
      color: #1c1c1c !important;
      background-color: #f2f2f2;
    }
  }
}

.borderColor {
  margin-left: 0px !important;
}

button.scopeInput {
  width: 120px !important;
  height: 34px;
  margin-left: 10px !important;
  background: #ffffff;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
  font-family: 'Lexend' !important;
  font-style: normal;
  font-weight: 350 !important;
  font-size: 14px !important;
  line-height: 18px;
  text-align: center;
  color: #585858 !important;
  padding: 8px 16px !important;
  &:hover,
  &:focus {
    color: #585858 !important;
    border: 1px solid #e9e9e9 !important;
    border-radius: 8px !important;
  }
}

.cellScope {
  .borderSearchIcon {
    position: absolute;
    right: 25% !important;
    top: 40% !important;
    z-index: 9;
    path {
      fill: #495057;
    }
  }
}

.cellSpaceDiv {
  margin-left: 10px !important;
}

.bodyfooterDiv {
  padding-left: '0px';
  padding-right: '0px';
  margin-left: '10px';
  margin-right: '10px';
  margin-bottom: '10px';
}

.CellFooter {
  border-top: 1px solid #e9e9e9 !important;
  margin: 0px !important;
  justify-content: center !important;
  align-items: center;
  padding: 8px 16px;
}

.rowProperty {
  box-sizing: border-box;
  padding: 8px 16px;
  gap: 8px;
  width: 260px;
  height: 34px;
  overflow-x: scroll;
  background: #ffffff;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #585858;
  &:hover,
  &:focus-visible {
    outline: none;
    border: 1px solid #e9e9e9 !important;
  }
}

.matricDiv {
  width: 30px !important;
  height: 34px !important;
  align-items: center !important;
  justify-content: center !important;
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
}

.borderCollapse > button {
  padding: 8px 16px;
  width: 140px !important;
  height: 34px;
  background: #ffffff;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #585858;
  &:hover,
  &:focus {
    background: #ffffff;
    border: 1px solid #e9e9e9 !important;
  }
}

.borderCollapseDiv {
  width: 140px !important;
  .borderSearchIcon {
    position: absolute;
    right: 8% !important;
    top: 40% !important;
    z-index: 9;
    path {
      fill: #495057;
    }
  }
  div {
    a {
      left: 0px !important;
      &:hover {
        background-color: #f2f2f2 !important;
        color: #585858 !important;
      }
      &.active {
        color: #1c1c1c !important;
        background-color: #f2f2f2;
      }
    }
  }
}

.webaddress-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.web-address-modal {
  .modal-body {
    padding-bottom: 16px !important;
  }
  .modal-footer {
    border-top: 1px solid #e7e9f5;
    padding: 16px;
    display: flex;
    justify-content: center;
    gap: 8px;
  }
  .modal-header {
    border-bottom: 1px solid #e7e9f5;
  }
}

.web-address-modal #web-address-modal {
  .webaddress-input {
    border: 1px solid #e9e9e9;
    padding: 8px 16px;
    color: #585858;
    height: 34px;
    &:focus {
      border: 1px solid #707070;
      box-shadow: none;
    }
    &:hover {
      border: 1px solid #ababab;
    }
  }
  .webaddress-input::placeholder {
    color: #8f8f8f;
    font-size: 14px;
    font-weight: 350;
  }
  .webaddress-input:focus::placeholder {
    opacity: 0;
  }
  .webaddress-input:focus {
    border: 1px solid #ababab;
    box-shadow: none !important;
  }
}
