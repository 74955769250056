@import '../../assets/scss/color.scss';

.lint-custom-modal {
  .btn-close {
    background: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
  .modal-title {
    height: 20px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #585858;
  }

  .modal-90w {
    .modal-content {
      width: 952px;
      height: 650px;
      position: absolute;
      top: 170px;
      left: 350px !important;
      border-radius: 16px !important;
    }
    .modal-body {
      padding: 0px !important;
    }
    max-width: none !important;
    width: 90% !important;
  }

  .modal-header {
    padding: 16px;
    padding-bottom: 16px !important;
    height: 56px;
  }
  .modal-content {
    justify-content: center;
    border-color: 1px solid #e9e9e9 !important;
    max-width: 950px !important;
    border-radius: 16px;
  }

  .modal-content:has(.hotspot) {
    left: unset !important;
  }

  .hotspot-submodal {
    justify-content: center !important;
  }

  .hotspot-submodal .modal-content {
    left: unset !important;
  }



  .close-icon {
    cursor: pointer;
    position: relative;
    bottom: 5px;
    color: #000000;
    opacity: 1;

    &:hover {
      opacity: 0.75;
    }
  }

  .addordropmodal {
    justify-content: center;
    min-height: 100vh !important;
    left: 0px !important;
    max-width: none !important;
    width: 90% !important;
    .modal-content {
      justify-content: center;
      border-color: 1px solid #e9e9e9 !important;
      max-width: 950px !important;
      border-radius: 16px;
      left: 0px !important;
    }
  }

  .modal-body {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    font-family: Lexend;
    color: #585858;
    padding-bottom: 0px !important;
  }

  .preview-modal-class {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    font-family: Lexend;
    color: #585858;
    padding-bottom: 5px !important;
  }

  .editor-custom-cancel-button,
  .editor-custom-button {
    padding: 5px 20px;
    font-size: 14px;
    color: $color_ultramarine !important;
    border-color: $color_ultramarine !important;
  }

  .btn.editor-custom-button:disabled {
    opacity: 1;
    background-color: #e9e9e9 !important;
    border: none;
    color: #707070 !important;
    cursor: not-allowed;
  }

  .editor-custom-cancel-button {
    &:hover,
    &:active,
    &:focus-visible {
      background-color: transparent !important;
      color: $color_ultramarine !important;
      border-color: $color_ultramarine !important;
    }
  }

  .modal-footer {
    padding: 0px;
  }

  .editor-btn-primary {
    background-color: $color_ultramarine !important;
    color: #fff !important;
  }
}

.lint-custom-modal > *:first-child {
  justify-content: center !important;
}

.custom-base-modal {
  .modal-body {
    padding: 1rem !important;
  }

  .modal-header {
    padding: 1rem 1rem 0.5rem 0 !important;
    border-bottom: 1px solid #dee2e6 !important;
  }

  .modal-footer {
    padding: 0.75rem !important;
  }
}

.lint-custom-modal .exit-preview-btn.btn {
  background-color: #ffffff;
  border: none;
  padding: 0;
  &:active,
  &:focus-visible,
  &:hover {
    background-color: #ffffff;
    border: none;
  }
}

.preview-modal-body {
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.lint-custom-modal #lint-custom-modal-Cancel-button,
.web-address-modal .modal-footer .editor-custom-cancel-button {
  margin: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  height: 34px;
  gap: 8px;
  color: #585858 !important;
  background: #ffffff !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 8px;

  &:hover {
    border: 1px solid #d8d8d8 !important;
    color: #585858 !important;
    background-color: #f8f8f8 !important;
  }
}

.web-address-modal .modal-footer .editor-custom-button {
  border: none;
  height: 34px;
  &:hover {
    background-color: #0e1b7a !important;
  }
}

.model-footer-element {
  padding: 16px !important;
  border-top: 1px solid #dee2e6 !important;
  gap: 8px;
}

.lint-custom-modal #lint-custom-modal-Exit-button {
  margin: 0px !important;
  &:hover {
    background-color: #001084 !important;
  }
}

.lint-custom-modal .delete-button {
  background-color: #af1c13 !important;
  border: 1px solid #af1c13 !important;
  margin: 0px !important;
}

#lint-custom-modal-Previous-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 91px;
  height: 34px;
  background: #ffffff;
  border-radius: 8px;
  border: none !important;
  &:disabled {
    color: #ababab !important;
  }
  &:not(:disabled) {
    color: #585858 !important;
  }
}

.icon-background-container {
  width: 24px;
  height: 24px;
  border: 1px solid rgb(233, 233, 233);
  background-color: rgb(244, 244, 244);
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.lint-custom-modal .media-modal-body {
  padding-top: 0;
}

.table-modal .modal-content {
  border-radius: 16px;
}

#lint-custom-modal-Remove-button {
  background-color: #af1c13 !important;
  border-color: #af1c13 !important;
  margin: 0px !important;
  &:hover {
    background-color: #a11a11 !important;
  }
}
