@import '../../assets/scss/color.scss';

.link-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 1rem !important;

  .modal-content {
    border: 1px solid #e7e9f5 !important;
    border-radius: 16px !important;
  }
}

.link-modal-dialog {
  margin: 0 auto !important;
  max-width: 468px !important;
  width: 100% !important;
  position: relative !important;
  pointer-events: auto !important;
}

.link-modal-header {
  border-bottom: 1px solid #e7e9f5 !important;
  padding: 1rem !important;
  position: relative !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  .modal-title {
    margin: 0 !important;
  }
}

.link-modal-title {
  display: flex !important;
  align-items: center !important;
  gap: 0.5rem !important;
  color: $color_dorado !important;
  font-family: 'Lexend', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.link-modal-icon-wrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: $tile-grey !important;
  border: 1px solid #e7e9f5 !important;
  border-radius: 8px !important;
  width: 24px !important;
  height: 24px !important;

  svg {
    width: 16px !important;
    height: 16px !important;
    color: $color_dorado !important;
  }
}

.link-modal-close {
  background: none;
  border: none;
  padding: 4px;
  color: $color_dorado;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    color: $color_dorado;
  }

  &:hover {
    opacity: 0.8;
  }
}

.link-modal-body {
  padding: 24px 16px !important;
  color: $color_dorado !important;
  font-family: 'Lexend', sans-serif !important;
  font-weight: 350 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: 0 !important;
}

.link-modal-footer {
  border-top: 1px solid #e7e9f5 !important;
  padding: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  gap: 0.5rem !important;
}

.link-modal-button {
  min-width: 100px !important;
  border-radius: 8px !important;
  font-family: 'Lexend', sans-serif !important;
  padding: 0.5rem 1rem !important;
}

.link-modal-button-primary {
  background-color: $color_ultramarine !important;
  border-color: $color_ultramarine !important;
  color: white !important;

  &:hover {
    background-color: darken($color_ultramarine, 5%) !important;
    border-color: darken($color_ultramarine, 5%) !important;
  }
}

.link-modal-button-secondary {
  background-color: $white !important;
  border: 1px solid $modal-border !important;
  color: $color_dorado !important;

  &:hover {
    background-color: darken(white, 5%) !important;
    border-color: $color_gainsboro !important;
  }
}

