@import '../../../../assets/scss/color.scss';

.lint-media-modal .lint-image-modal-right-container {
  border-right: 1px solid #d8d8d8;
  padding: 0 16px 0 0;
  .nav-tabs {
    width: 76%;
    .nav-item {
      overflow: unset;
    }
  }
}

.icon-container {
  .category-list {
    border-right: 1px solid $color_light_gray;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    text-align: left;
    color: #585858;
    .selected-category {
      color: $color_ultramarine;
    }
  }
  .icon-list {
    .lint-icon:hover {
      color: $color_ultramarine;
    }
    .selected-icon {
      color: $color_ultramarine;
      border: 1px solid $color_ultramarine;
    }
    .material-icons {
      font-size: 32px;
      padding: 10px;
    }
  }
  .icon-list,
  .category-list {
    height: 200px;
    overflow-y: scroll;
  }
}
.disabled {
  background: #fff 0 0 no-repeat padding-box;
  opacity: 0.2;
}

.lint-image-row {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.lint-image-row .nav-tabs {
  width: unset !important;
}

.image-modal-divider-content {
  height: 21px;
  border-bottom: 1px solid #909090;
  text-align: center;
  margin: 0 20px 21px;
}

.image-modal-divider-text {
  font-size: 12px;
  background-color: #ffffff;
  padding-top: 11px !important;
  margin: 0px -15px 0px;
  color: #909090;
}

.lint-media-modal {
  color: $black;
  font-size: 14px;
  font-style: normal;
  line-height: normal;

  select.form-select {
    width: 110px;
    border: 1px solid $dim-grey;
    border-radius: 4px;
    outline: none;
    color: $black;
    font-size: 12px !important;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }
  }

  select:disabled {
    cursor: default;
  }
}

.lint-custom-element-list-container {
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;

  .d-flex {
    padding: 5px;
    height: 50%;
    width: 20% !important;
  }
  .element-thumbnail {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 14px !important;
    line-height: 18px;
    text-align: center !important;
    color: #1c1c1c;
  }
  .element-thumbnail:hover,
  .selected-media {
    color: $color_ultramarine;
    cursor: pointer;

    img {
      border: 1px solid $color_ultramarine;
    }
  }

  .lint-custom-element-list-thumbnail {
    height: 65px;
    background: $color_alto 0 0 no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    object-fit: scale-down;
  }

  .lint-custom-element-list-title {
    font-size: 14px;
    max-width: 104px;
  }
}

.lint-image-local-upload-input-message {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300 !important;
  font-size: 10px !important;
  text-align: center;
  color: #707070 !important;
}

.lint-image-modal-body-content-right-container {
  padding: 16px;
  width: 25%;
  .text-danger {
    p {
      color: $loree-red;
      font-size: 12px;
      padding: 0;
      margin: 0;
    }
  }
  .right-container-layout {
    height: 320px !important;
    .disable-image-upload {
      border-color: $color_gray !important;
      opacity: 0.3;
      p {
        color: $color_gray !important;
      }
    }

    #lint-external-image-input {
      gap: 8px;
      height: 32px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      text-align: left;
      color: #707070;
    }

    .upload-local-image {
      border: 1px dashed #b5badf;
      border-radius: 12px;
      padding: 16px 24px;
      gap: 16px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 270px;

      .upload-image-icon-holder {
        cursor: pointer;
        p {
          font-family: 'Lexend';
          font-style: normal;
          font-weight: 400 !important;
          font-size: 14px;
          line-height: 18px;
          text-align: center !important;
          color: #112299;
        }
      }
    }

    .image-modal-icon {
      position: absolute;
      padding: 6px;
      min-width: 30px;
      z-index: 1;
    }

    .image-modal-icon-inputfield {
      position: absolute;
      padding: 7px 14px;
      min-width: 30px;
      z-index: 1;
    }

    .invalid-url-state {
      border-color: $loree-red !important;
    }

    input {
      width: 100%;
      height: 40px;
      padding: 0px 0px 0px 40px;
      border: 1px solid $color_ultramarine;
      border-radius: 4px;
      line-height: 2.5;

      &:focus-visible {
        outline: 0;
      }
    }

    button {
      background-color: $white;
      width: 100%;
      height: 36px;
      padding-left: 45px;
      border: 1px solid $color_gray;
      font-size: 12px;
      color: rgba(88, 88, 88, 0.4);
      text-align: left;
      vertical-align: middle;

      &.active {
        color: $black;
        opacity: 1;
      }
    }
  }
}
