@import '../../assets/scss/color.scss';

.customize-container {
  overflow-y: auto;
  width: 220px;
  max-height: 95vh;

  .accordion-button {
    box-shadow: none !important;
    background: none !important;
  }

  .unit-placeholder {
    border: 1px solid #6c757d;
    border-radius: 5px;
    text-align: center;
  }
}

.title-header {
  position: fixed;
  background-color: #fff;
  z-index: 5;
}

.alignment-button-group {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.margin-group {
  display: flex;
  width: 115px;
}

.background-color-button {
  display: flex;
  justify-content: space-between;
}

.border-radius-form {
  display: flex;
  justify-content: space-between;
}

.customize-toggle {
  position: absolute;
  margin-left: -16px;
  cursor: pointer;
  z-index: 6;
}

.section-accordion {
  margin-top: 12px;
  border: 1px solid #e9e9e9;
  border-radius: 12px;

  .inner-content {
    height: 25px;
    margin: 16px;
  }
}

.section-content {
  border-top: 1px solid #e9e9e9;
  padding: 16px;
}

.option-content {
  border-bottom: 1px dashed #8f8f8f;
}

.design-section-text {
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 600;
}

.accordion-title {
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  font-weight: 400;
  margin-left: 12px;
  cursor: pointer;
}

.dropdown-box.btn-primary {
  display: flex;
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid #707070;
  background: #fff;
  color: #000;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  z-index: 0 !important;

  &:after {
    display: none;
  }

  &:focus-visible {
    box-shadow: none !important;
  }

  &:hover,
  &:active,
  &.show {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #112299;
  }
}

.dropdown-icon {
  position: absolute;
  right: 12px;
  top: 12px;
}

.fontsize-dropdown.btn-primary {
  width: 100px;
  margin-right: 13px;
  border-radius: 6px !important;
}

.dropdown-input-menu.dropdown-menu {
  width: 100%;
  border-color: #112299;
  height: 190px;
  overflow-x: auto;
  padding: 0;

  .dropdown-item:hover {
    background-color: #112299;
    color: #fff;
  }
}

.weight-dropdown.dropdown-menu {
  height: 100px;
}

.alignment-buttons.btn-primary {
  background-color: #fff !important;
  border: none;

  &:active {
    background-color: #fff !important;
  }
}

.alignment-buttons.btn-primary.btn.active {
  z-index: inherit;
  svg {
    path {
      fill: #112299 !important;
    }
  }
}

.margin-top-input.form-control,
.margin-bottom-input.form-control {
  border: 1px solid $color-lti-frame;
  border-radius: 8px;
  padding: 8px 16px;
  height: 34px;
  width: 86px;
  color: $color_dorado;
  font-size: 14px;
  font-weight: 350;
  &:hover,
  &:focus {
    border: 1px solid $gray-light;
    box-shadow: none;
  }
}

.margin-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.margin-group {
  display: flex;
  gap: 16px;
  align-items: center;
}

.color-picker.btn-primary {
  width: var(--Loree-Padding, 20px);
  height: var(--Loree-Padding, 20px);
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  background: #72c069;

  &:active,
  &:hover {
    border-color: #e9e9e9 !important;
  }
}

.checkbox-label {
  label {
    cursor: pointer;
  }
  input[type='checkbox'] {
    cursor: pointer;
  }
}

.icon-label {
  input[type='radio']:checked:after {
    width: 8px;
    height: 8px;
    border-radius: 15px;
    top: -26px;
    left: 3px;
    position: relative;
    background-color: #112299;
    content: '';
    display: inline-block;
    visibility: visible;
  }

  input[type='radio']:checked::before {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -2px;
    left: 0px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #112299;
    background-color: white;
  }

  input[type='radio']:not(:checked)::before {
    width: 14px;
    height: 14px;
    border-radius: 15px;
    top: -2px;
    left: 0px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #112299;
    background-color: white;
  }
}

.links-tab-label {
  margin-bottom: 0 !important;
  input[type='radio'] {
    accent-color: #112299;
    margin-right: 5px;
  }
}

#color-picker > :not(.border-element-container) {
  color: #585858 !important;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    /* Firefox */
    -moz-appearance: textfield;
  }
}
#color-picker {
  .border-element-container {
    margin: 0px 16px !important;
  }
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    width: 100%;
  }
  input[step='1'] {
    width: 40px;
    margin-left: 6px;
    margin-top: 2%;
  }
}

.designsec-accordion,
.mcq-accordion {
  .section-header-title,
  .border-title,
  .icon-title {
    font-size: 16px;
    font-weight: 350;
    font-family: Lexend;
    color: $color-nero;
  }
}

.designsec-accordion,
.mcq-accordion {
  .custom-header-title:hover {
    .section-header-title,
    .border-title,
    .icon-title {
      color: $color_ultramarine;
    }
    svg path {
      fill: $color_ultramarine;
    }
  }
}

.designsec-accordion,
.mcq-accordion,
.link-radiobutton-group {
  .design-section-text {
    color: $color_dorado;
    font-size: 14px;
    font-weight: 350;
    font-family: Lexend;
  }
}

.icon-items {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .form-check-label {
    color: $color_dorado;
    font-size: 14px;
    font-weight: 350;
    font-family: Lexend;
  }
  .icons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .helper-text {
    color: $dim-grey;
    font-size: 12px;
    font-weight: 350;
  }
}

input[type='number'].enable-spinner {
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.sectionTitle {
  font-family: Lexend !important;
  font-style: normal !important;
  font-weight: 350 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #909090 !important;
  margin-bottom: 0px !important;
}

.sectionBody {
  padding: 16px;
}

.sectionBody > div {
  border: none;
}

.sectionBody > div > div:first-child {
  border: 1px solid #e9e9e9;
  border-radius: 8px !important;
}

.sectionBody > div > div {
  div > .tooltip-bottom,
  button > div > .tooltip-bottom {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 2px 10px rgba(28, 28, 28, 0.08);
    border-radius: 8px;
    font-family: Lexend;
    font-style: normal;
    font-weight: 350;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #585858 !important;
  }
}

.sectionBody > div > .loree-tiptap-editor {
  margin-top: 10px !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;

  div {
    border: none !important;
  }
}
.sectionBody > div > div > div[tabindex='-1'] {
  border: 1px solid #e9e9e9 !important;
  border-radius: 6px !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li > span,
  li {
    font-family: 'Lexend' !important;
    font-style: normal !important;
    font-weight: 350 !important;
    font-size: 14px !important;
    color: #585858;
  }
  button,
  li {
    &:hover {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span {
        background-color: #f2f2f2 !important;
        color: #585858 !important;
      }
      background-color: #f2f2f2 !important;
      color: #585858 !important;
    }
    &.is-active,
    &.selected {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span {
        background-color: #f2f2f2 !important;
        color: #1c1c1c !important;
      }
      background-color: #f2f2f2 !important;
      color: #1c1c1c !important;
    }
  }
  button {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 350;
    font-size: 12px;
    line-height: 18px;
  }
  button:not([disabled]) {
    color: #585858;
    svg path {
      fill: #585858;
    }
  }
  button.disabled {
    color: #ababab !important;
    svg path {
      color: #ababab !important;
    }
  }
}

.sectionBody > div > div > div > div[tabindex='-1'] {
  border: 1px solid #e9e9e9 !important;
  border-radius: 6px !important;
  a {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 18px;
  }
  a[name='LintTables'] {
    color: #585858 !important;
  }
  a[aria-expanded='false'] {
    color: #585858;
  }
  div[role='dialog'] {
    color: #585858 !important;
    border: 1px solid #e9e9e9 !important;
    border-radius: 6px !important;
    a:not(:disabled):hover {
      color: #1c1c1c !important;
      background-color: #f2f2f2 !important;
    }
  }
}

.sectionBody {
  button.is-active {
    .IconContainer {
      border: 1px solid #b5badf !important;
      background: #e7e9f5;
      svg path {
        fill: #112299 !important;
      }
    }
  }
  button[aria-expanded='true'] {
    .IconContainer {
      border: 1px solid #b5badf !important;
      background: #e7e9f5;
      svg path {
        fill: #112299 !important;
      }
    }
  }
  button:not(:disabled):hover {
    .IconContainer {
      border: 1px solid #b5badf !important;
      background: #e7e9f5;
    }
  }
}
button.loreeInteractiveEditor_historyButton__8v0y9:not(:disabled) {
  .IconContainer {
    border: 1px solid #e9e9e9 !important;
  }
}

.mcq-accordion {
  .icon-items {
    display: flex;
  }
  .icon-label {
    margin: 0;
  }
  .form-switch {
    display: flex;
    align-items: center;
    gap: 8px;
    .form-check-input {
      width: 26px;
      height: 16px;
      margin-top: 0;
    }
  }
  .form-check {
    margin-bottom: 0;
  }
}

.mcq-accordion .form-switch .form-check-input:focus:not(:checked) {
  border: 1px solid #e9e9e9;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e);
}

.behaviour-container {
  line-height: 14px;
  .form-switch {
    margin-bottom: 2px;
  }
}

.designsec-accordion {
  .accordion-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
.option_section {
  border: 0px !important;
  font-family: 'Lexend' !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #585858 !important;
}

.styled-tool-tip > .tooltip-bottom {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 2px 10px rgba(28, 28, 28, 0.08);
  border-radius: 8px;
  font-family: Lexend;
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #585858 !important;
}

.lablelInput {
  color: #909090 !important;
  &:hover,
  &:focus {
    border: 1px solid #e9e9e9 !important;
    box-shadow: none !important;
  }
}

.imagemodal {
  border-right: 1px solid #e9e9e9 !important;
  padding: 8px 16px;

  button:disabled {
    border: 1px solid #909090 !important;
    .styled-tool-tip {
      svg path {
        fill: #909090 !important;
      }
    }
  }
  button:not(:disabled) {
    border: 1px solid #112299 !important;
    .styled-tool-tip {
      svg path {
        fill: #112299;
      }
    }
    &:hover {
      background-color: #eff1fe !important;
    }
  }
}

.spacing-container,
.dnd-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  background-color: #112299;
  color: #fff;
  font-size: 14px;
  font-weight: 450;
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
  margin-top: 0;
  margin-bottom: 0;
  &:hover,
  &:active,
  &:focus {
    background-color: #0e1b7a;
    border: none;
  }
}

.primary-button.btn-primary:disabled {
  background-color: #e9e9e9;
  color: #585858;
  opacity: 0.5;
}

.btn-primary.primary-button:not(:disabled):not(.disabled):active {
  background-color: #0e1b7a;
  border: none;
}

.secondary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  background-color: #fff !important;
  color: #585858;
  font-size: 14px;
  font-weight: 450;
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid #d8d8d8;
  margin-top: 0;
  margin-bottom: 0;
  &:hover,
  &:active,
  &:focus {
    background-color: #f8f8f8 !important;
    border: 1px solid #d8d8d8;
    color: #585858;
  }
}

.btn-primary.secondary-button:not(:disabled):not(.disabled):active {
  background-color: #f8f8f8;
  border: 1px solid #d8d8d8;
  color: #585858;
}

.btn-outline-secondary.secondary-button:not(:disabled):not(.disabled):active {
  background-color: #f8f8f8;
  border: 1px solid #d8d8d8;
  color: #585858;
}

.image-wrapper-container,
.replace-btns {
  .primary-button {
    margin-left: 8px;
  }
}
